import HttpClient from '../api/HttpClient'

class OkloReservationsApiClient extends HttpClient {
    constructor(baseURL, token, tenant) {
        super({
          baseURL,
          token
        });
        this.tenant = tenant;
      }

      get userProfile() {
        return {
          get: () => this.get('user-tenants')
        };
      }

      get destinations() {
        return {
          get: (prefix) => this.get(`${this.tenant}/lookup/locations?term=${prefix}`)
        };
      }

      get agents() {
        return {
          get: (criteria) => {
            const query = this.createODataQuery(criteria);
            return this.get(`${this.tenant}/agents${query}`);
          },
        };
      }

      get locations() {
        return {
          get: () => this.get(`${this.tenant}/locations`)
        };
      }

      createODataQuery = criteria =>{
        criteria = criteria ?? {};
        const filter = criteria.filter ? `$filter=${criteria.filter}` : '';
        const select = criteria.select ? `$select=${criteria.select}` : '';
        const orderBy = criteria.orderBy ? `$orderby=${criteria.orderBy}` : '';
        const top = criteria.top > 0 ? `$top=${criteria.top}` : '';
        const expand = criteria.expand ? `$expand=${criteria.expand}` : '';
        const apply = criteria.apply ? `$apply=${criteria.apply}` : '';
        let query = '?';
        if (top) {
          query += `${top}&`
        }
        if (filter) {
          query += `${filter}&`
        }
        if (select) {
          query += `${select}&`
        }
        if (orderBy) {
          query += `${orderBy}&`
        }
        if(expand){
          query += `${expand}&`
        }
        if (apply) {
          query += `${apply}&`
        }
        return query;
      }
    
    
}

export default OkloReservationsApiClient